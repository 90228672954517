import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { formatDate } from '@angular/common';
import * as _ from 'lodash';

import { ContactData } from '../../../model/contact-data';
import { phoneMaskMobile } from '../../../app.constants';
import { ContactDataCruiseInterface } from '../../../model/contact-data-cruise.interface';
import { NotifyService } from '../../../services/notify.service';
import { SearchboxService } from '../../templates/searchbox/searchbox.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: 'app-traveler-name-dialog',
  templateUrl: './traveler-name-dialog.component.html',
  styleUrls: ['./traveler-name-dialog.component.scss'],
  providers: [
    SearchboxService
  ]
})
export class TravelerNameDialogComponent implements OnInit, OnDestroy {
  @ViewChild('phoneElement', { static: false }) public phoneElement: ElementRef;
  public personalDataForm: FormGroup;
  public personalDataValid: boolean = true;
  public contactData: ContactData = new ContactData();
  public phoneMask = phoneMaskMobile;
  public formSending: boolean = false;
  public searchboxContactData: any;
  public searchboxActiveForm: FormGroup;
  public isMultiCity: boolean;
  public isMultiCityValid: boolean;
  public flightOptions: { travelers: number, cabin: string };
  public formValue: { [key: string]: any };
  public isOneWayForm: boolean;
  public showThirdFlight: boolean;
  public inputType: string;

  protected destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
    private searchboxService: SearchboxService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  public ngOnInit(): void {
    this.initPersonalDataForm();
    this.formValue = _.cloneDeep(this.searchboxActiveForm.value);
  }

  public onPhoneNumberChange(event: Event): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    if (inputElement.value.indexOf('(') < 0) {
      this.contactData.phone = inputElement.value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    if (this.contactData.phone.indexOf('+') < 0) {
      this.contactData.phone = '+1 ' + this.contactData.phone;
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid: boolean = !isWhitespace;
    return isValid ? null : { 'isEmpty': true };
  }

  public initPersonalDataForm(): void {
    this.personalDataForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required,
        Validators.pattern('^\\+1 [(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$'),
        this.phoneValidator()])]
    });
    this.contactData = { ...this.contactData, ...this.searchboxContactData };
    if (this.contactData.phone) {
      this.contactData.phone = '+1 ' + this.contactData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  }

  public executeRecaptchaCheckAction(): void {
    this.recaptchaV3Service.execute('submit')
        .pipe(takeUntil(this.destroy$))
        .subscribe((token) => {
          if (token) {
            this.getQuote();
          }
        });
  }

  public getQuote(): void {
    if (this.personalDataForm.get('phone').hasError('phoneFormatError')) {
      this.personalDataValid = false;
      this.notifyService.warn('Incorrect area code', {
        closeWith: ['click', 'button'],
        timeout: 5000
      });
      return;
    }
    if (this.personalDataForm.valid && (this.searchboxActiveForm.valid && !this.isMultiCity ||
        this.searchboxActiveForm.valid && this.isMultiCityValid)) {
      this.personalDataValid = true;
      this.formSending = true;

      const sendContactData: ContactData = _.clone(this.contactData);
      const phonePlusIndex: number = sendContactData.phone.indexOf('+');
      if (phonePlusIndex >= 0) {
        sendContactData.phone = sendContactData.phone.slice(phonePlusIndex + 2);
        sendContactData.phone = _.replace(sendContactData.phone, new RegExp('[ ()-]', 'g'), '');
      }

      this.searchboxService.sendRequest(sendContactData).subscribe(
        response => {
          this.formSending = false;
          if (_.eq(response.status, 'FAILED')) {
            alert(response.data.details);
          } else {
            this.closeDialog(true);
          }
        },
        error => {
          this.formSending = false;
          console.warn('Can\'t process your request now. Please try later.');
        });
    } else {
      this.personalDataValid = false;
      this.notifyService.warn('Please fill flight data', {
        closeWith: ['click', 'button'],
        timeout: 10000
      });
    }
  }

  public capitalize(value: string): string {
    return _.capitalize(value);
  }

  public prepareContactData(): ContactDataCruiseInterface {
    const transformedPhone: string = _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '');
    return {
      firstName: this.contactData.firstName,
      email: this.contactData.email,
      phone: !this.contactData.phone ? null : this.contactData.phone[0] === '+' ? transformedPhone.slice(2) : transformedPhone,
      cCode: '1'
    };
  }

  public extractTextFromBrackets(from: string, to: string): string {
    if (!from || !to || from.indexOf('(') < 0 || to.indexOf('(') < 0) {
      return '';
    }

    const regex: RegExp = /\(([^)]+)\)/;
    const matchFrom = from.match(regex);
    const matchTo = to.match(regex);

    return `${matchFrom[1]}-${matchTo[1]}`;
  }

  public hasDirectionValue(from: string, to: string): boolean {
    return !(!from || !to || from.indexOf('(') < 0 || to.indexOf('(') < 0);
  }

  public hasDateValue(date: NgbDateStruct): boolean {
    return date && !!date.month;
  }

  public getDateValue(date: NgbDateStruct): string {
    if (this.hasDateValue(date)) {
      const formattedDate = new Date(date.year, date.month - 1, date.day);
      return formatDate(formattedDate, 'MM/dd', 'en-US');
    } else {
      return '';
    }
  }

  public customPipe = (conformedValue: string, config: any): false | string | { value: string, indexesOfPipedChars?: number[] } => {
    const rawValue = config.rawValue.replace(/[^\d+]/g, '');
      if (rawValue.length === 10 && !rawValue.includes('+')) {
        return {
          value: '+1 ' + rawValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        };
      }
      if (rawValue.length === 11 && !rawValue.includes('+') && rawValue.startsWith('1')) {
        return {
          value: '+1 ' + rawValue.slice(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        };
      }

    return {
      value: conformedValue
    };
  }

  public closeDialog(isSuccess: boolean = false, moveBack: boolean = false): void {
    this.activeModal.close({
      isSuccess,
      contactData: this.prepareContactData(),
      moveBack
    });
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!_.isEmpty(control.value) && control.value.startsWith('+1 (1') ) {
        return { phoneFormatError: true };
      } else {
        return null;
      }
    };
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
