import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {QueryFlightParams} from '../../../model/query-flight-params';
import * as _ from 'lodash';
import {Flight} from '../../../model/flight';
import {ContactData} from '../../../model/contact-data';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {HowItWorksDialogComponent} from '../../modal/how-it-works-dialog/how-it-works-dialog.component';
import {isPlatformBrowser} from '@angular/common';
import {DataService} from '../../../../shared/shared-data.service';
import {Subscription} from 'rxjs/Subscription';
import {NgbDateCustomParserFormatter} from '../../../model/ngb-date-custom-parser-formatter';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import 'rxjs/Rx';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';
import {AppStorage} from '../../../../storage/universal.inject';
import {formatDate, isEmptyDate, prepareDate} from '../../../../shared/util/date-util';
import {phoneMask, phonePattern} from '../../../app.constants';
import {getElementById, fitCountrySelect, getElementsByClass} from '../../../../shared/util/DOM-util';
import {NotifyService} from '../../../services/notify.service';
import {TrackingService} from '../../../services/tracking.service';
import { debounceTime, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import {SearchboxService} from '../searchbox/searchbox.service';
import { FlyingFromDialogComponent } from '../../modal/flying-from-dialog/flying-from-dialog.component';
import { FlyingToDialogComponent } from '../../modal/flying-to-dialog/flying-to-dialog.component';
import { RoundTripDateDialogComponent } from '../../modal/round-trip-date-dialog/round-trip-date-dialog.component';
import { FlightOptionsDialogComponent } from '../../modal/flight-options-dialog/flight-options-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TravelerNameDialogComponent } from '../../modal/traveler-name-dialog/traveler-name-dialog.component';
import { TravelerPhoneDialogComponent } from '../../modal/traveler-phone-dialog/traveler-phone-dialog.component';
import { TravelerEmailDialogComponent } from '../../modal/traveler-email-dialog/traveler-email-dialog.component';
import { BundleSaveDialogComponent } from '../../modal/bundle-save-dialog/bundle-save-dialog.component';
import { SearchboxFlightsMobileService } from '../../../services/searchbox-flights-mobile.service';
import { FlightMobileInterface, FlightOptions } from '../../../model/flight-mobile.interface';
import {
  extractAirportCodeFromBrackets,
  removeBrackets,
  transformToRoundBrackets,
  transformToSquareBrackets
} from '../../../model/transform-brackets';
import {
  DepartReturnDateDialogComponent
} from '../../modal/depart-return-date-dialog/depart-return-date-dialog.component';
import { NgbDateCustomParserFormatterFlights } from '../../../model/ngb-date-custom-parser-formatter-flights';
import * as moment from 'moment';
import {
  FlyingCombinedDirectionDialogComponent
} from '../../modal/flying-combined-direction-dialog/flying-combined-direction-dialog.component';
import { ContactFieldsListEnum } from '../../../model/contact-fields-list.enum';
import { ReCaptchaV3Service } from "ng-recaptcha";
declare let GetCustomEvent: Function;

const PHONE_KEY = makeStateKey('phone');
const PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');


@Component({
  selector: 'app-searchbox-new-generation',
  templateUrl: './searchbox-new-generation.component.html',
  styleUrls: ['./searchbox-new-generation.component.scss'],
  providers: [
    SearchboxService,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatterFlights
    }
  ]
})
export class SearchboxNewGenerationComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('phoneSelect', { static: false }) phoneSelect;
  queryPanelForm: FormGroup;
  roundTripForm: FormGroup;
  oneWayForm: FormGroup;
  multiCityForm: FormGroup;
  activeForm: FormGroup = this.roundTripForm;

  /* Flight params */
  tripContent: boolean;
  cabinContent: boolean;
  tripTypeContent: boolean;
  passengersContent: boolean;
  queryFlightParams = new QueryFlightParams;
  flight: Flight = new Flight;
  flight1: Flight = new Flight;
  flight2: Flight = new Flight;
  returnDate: NgbDateStruct;

  /* Datepicker variables*/
  maxDate: NgbDateStruct;
  minDateDepart: NgbDateStruct;
  minDateReturn: NgbDateStruct;
  minDateDepart1: NgbDateStruct;
  minDateDepart2: NgbDateStruct;
  firstDayOfWeek: number;
  displayMonths: number;
  navigation: string;

  normalFlight: boolean;
  submitDisabled: boolean;
  submitBtnText: string;
  activeDepart: boolean;
  activeDepart1: boolean;
  activeDepart2: boolean;
  activeReturn: boolean;

  /* Autocomplete data*/
  filteredFromData;
  filteredFromOneWayData;
  filteredFromMultiCityData;
  filteredToData;
  filteredToOneWayData;
  filteredToMultiCityData;
  filteredFrom1MultiCityData;
  filteredTo1MultiCityData;
  filteredFrom2MultiCityData;
  filteredTo2MultiCityData;
  locationData: any[] = [];

  searchFormValid: boolean;
  queryPanelValid: boolean;
  formSending = false;
  contactData: ContactData = new ContactData();
  phoneMask = phoneMask;

  showAddFlightButton = true;
  showThirdFlight = false;

  focusOut = false;
  mustSubmit = false;
  phoneVisible = false;
  subscription: Subscription;
  phone: string;
  initFlightDataNow: boolean;
  lastFlight = new QueryFlightParams;
  clickTripType = true;
  contactPhoneModel: string;
  flightSubmitted = false;
  public isMobileOrTablet: boolean;
  public isMobile: boolean;
  public flightMobileQuoteData: FlightMobileInterface;
  public removeBrackets = removeBrackets;
  public contactFieldsListEnum = ContactFieldsListEnum;
  public bundleSave: { addCruise: boolean, addHotel: boolean } = {
    addCruise: null,
    addHotel: null,
  };
  protected destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private searchboxService: SearchboxService,
              private cookieService: CookieService,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private dataService: DataService,
              private state: TransferState,
              @Inject(AppStorage) private appStorage: Storage,
              private trackingService: TrackingService,
              private notifyService: NotifyService,
              private modalService: NgbModal,
              private deviceService: DeviceDetectorService,
              private searchboxFlightsMobileService: SearchboxFlightsMobileService,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.flightMobileQuoteData = this.searchboxFlightsMobileService.getFlightQuoteData();
  }

  ngOnInit() {
    this.initPhone();
    this.isMobileOrTablet = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isMobile = this.deviceService.isMobile();
    this.normalFlight = true;
    this.searchFormValid = true;
    this.queryPanelValid = true;
    this.initTripContent();
    this.queryFlightParams.tripType = 'Round';
    this.submitBtnText = 'Get Quote';
    this.initForms();
    this.initFields();
    this.initAutocompleteForAll();
  }

  public executeRecaptchaCheckAction(): void {
    this.recaptchaV3Service.execute('submit')
        .pipe(takeUntil(this.destroy$))
        .subscribe((token) => {
          if (token) {
            this.getQuote();
          }
        });
  }

  get landingClass() {
    return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
  }

  routeTo(path: string): void {
    setTimeout(() => { this.router.navigateByUrl(path); } , 0);
  }

  changeMustSubmit(mustSubmit: boolean) {
    this.mustSubmit = mustSubmit;
  }

  initFields() {
    this.searchboxService.getInitQueryFlightParams().subscribe(
        response => {
          this.queryFlightParams = response;
          if (_.eq(response.cabin, 'BLANK')) {
            this.queryFlightParams.cabin = 'BUSINESS';
          }
          this.flight.from = response.flights[0].from;
          this.flight.to =  response.flights[0].to;
          this.flight.departDate = prepareDate(response.flights[0].departDate);
          this.returnDate = prepareDate(response.returnDate);
          this.minDateReturn = prepareDate(response.flights[0].departDate, 1, 'd');
          if (response.flights.length > 1) {
            this.flight1.from = response.flights[1].from;
            this.flight1.to = response.flights[1].to;
            this.flight1.departDate = prepareDate(response.flights[1].departDate);
            this.minDateDepart1 = prepareDate(response.flights[0].departDate);
          }
          if (response.flights.length === 3) {
            this.flight2.from = response.flights[2].from;
            this.flight2.to = response.flights[2].to;
            this.flight2.departDate = prepareDate(response.flights[2].departDate);
            this.minDateDepart2 = prepareDate(response.flights[1].departDate);
            this.showThirdFlight = true;
            this.showAddFlightButton = false;
          }
          this.setActiveForm(response.tripType);
          this.initFlightDataNow = true;
          if (isPlatformBrowser(this.platformId)) {
            this.initDatepicker();
          }
        },
        error => {
          if (isPlatformBrowser(this.platformId)) {
            this.initDatepicker();
          }
        }
    );
  }

  submitSearch() {
    if (this.activeForm === this.multiCityForm && !this.thirdFlightValid()) {
      return;
    }
    if (this.activeForm.valid && isPlatformBrowser(this.platformId)) {
      this.submit();
    }
  }



  submit() {
    this.fillQueryFlightParams();
    if (!_.isUndefined(this.queryFlightParams.cabin) && !_.isUndefined(this.queryFlightParams.passengers)) {
      if (this.modelChanged()) {
        this.formSending = true;
        this.disableButton(true);
        this.trackingService.getTrack().subscribe(() => {
              this.searchboxService.submitSearch(this.queryFlightParams).subscribe(
                  response => {
                    this.formSending = false;
                    this.disableButton(false);
                    if (!_.isNil(response.data.error)) {
                      alert(response.data.error);
                    } else {
                      // We lost dial code after contact form hid, to prevent it store code before (WSF-1766)
                      if (this.normalFlight === true && response.data.normal === false) {
                        this.contactData.cCode = this.dialCode;
                      }
                      this.normalFlight = response.data.normal;
                      // restore dial code (WSF-1766)
                      setTimeout(() => {
                        if (this.phoneSelect) {
                          if (this.isMobileOrTablet) {
                            if (!Object.keys(this.phoneSelect.getCountryData()).length) {
                              this.phoneSelect.setCountry('us');
                              this.phoneSelect.el.nativeElement.previousElementSibling.style.pointerEvents = 'none';
                              this.contactData.cCode = '1';
                            }
                          } else {
                            this.setCountry(this.contactData.cCode);
                          }
                        }
                      });
                      this.flightSubmitted = true;
                      this.lastFlight = _.clone(this.queryFlightParams);
                    }
                  },
                  error => {
                    this.formSending = false;
                    this.disableButton(false);
                  });
            },
            () => {
              this.formSending = false;
              this.enableButton();
              this.showWarnNoty();
            });
      }
    }
  }

  showWarnNoty(): void {
    this.notifyService.warn('Can\'t process your request now. Please try later.', {
      closeWith: ['click', 'button'],
      timeout: 10000
    });
  }

  private modelChanged() {
    return !_.isEqual(this.queryFlightParams, this.lastFlight);
  }

  private autoFillFromParam(name: string): void {
    if (_.isNil(name) || _.isEmpty(name)) {
      return;
    }

    this.searchboxService.getLocations(name.replace('-', ' ')).subscribe((response: any[]) => {
      if (response.length !== 0) {
        this.flight.to = response[0];
      }
    }, error => {
      //    do nothing
    });
  }

  private initPhone() {
    if (!_.isNil(this.state.get(PHONE_KEY, null as any))) {
      this.phone = this.state.get(PHONE_KEY, null as any);
    }
    if (!_.isNil(this.state.get(PHONE_VISIBLE_KEY, null as any))) {
      this.phoneVisible = this.state.get(PHONE_VISIBLE_KEY, null as any);
    }
    this.subscription = this.dataService.getData()
        .subscribe(sharedData => {
          if (_.eq(sharedData.type, 'phone')) {
            this.phone = sharedData.data;
          }
          if (_.eq(sharedData.type, 'phoneVisible')) {
            this.phoneVisible = sharedData.data;
          }
          if (_.eq(sharedData.type, 'autoFillTo')) {
            this.autoFillFromParam(sharedData.data);
          }
        });
  }

  private initTripContent() {
    this.queryFlightParams.cabin = 'BUSINESS';
    if (!_.isEmpty(this.appStorage.getItem('WF_CABIN_TYPE'))) {
      this.queryFlightParams.cabin = this.appStorage.getItem('WF_CABIN_TYPE');
    }
    this.queryFlightParams.passengers = 1;
    if (!_.isEmpty(this.appStorage.getItem('WF_ADULT'))) {
      this.queryFlightParams.passengers = Number(this.appStorage.getItem('WF_ADULT'));
    }
  }

  /* Start init autocomplete */
  private initAutocompleteForAll() {
    /* Init autocomplete for round trip */
    this.filteredFromData = this.initAutocomplete(this.roundTripForm, 'flyingFrom');
    this.filteredToData = this.initAutocomplete(this.roundTripForm, 'flyingTo');
    /* Init autocomplete for one way */
    this.filteredFromOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingFrom');
    this.filteredToOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingTo');
    /* Init autocomplete for multi city */
    this.filteredFromMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom');
    this.filteredToMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo');
    this.filteredFrom1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom1');
    this.filteredTo1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo1');
    this.filteredFrom2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom2');
    this.filteredTo2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo2');
  }

  private initAutocomplete(form: FormGroup, contrl: string) {
    return form.get(contrl).valueChanges
        .pipe(
            debounceTime(200),
            switchMap(value => {
                  if (!this.mustSubmit && !_.isEmpty(value) && _.isNil(value.match(/\D+\(\w+\)/i))) {
                    return this.searchboxService.getLocations(value.trim())
                        .pipe(
                            map((response: any[]) => {
                                  if (response.length === 0 && !_.isNull(value) && !_.isEmpty(value)) {
                                    return Array.of('No cities found');
                                  } else {
                                    return response;
                                  }
                                }
                            ));
                  } else {
                    return [];
                  }
                }
            ));
  }

  /* End init autocomplete */

  private initForms() {
    this.initRoundTripForm();
    this.initOneWayForm();
    this.initMultiCityForm();
    this.initQueryPanelForm();
    this.activeForm = this.roundTripForm;
  }

  private initRoundTripForm() {
    this.roundTripForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      departDate: ['', Validators.required],
      returnDate: ['', Validators.required]
    });
    this.roundTripForm.valueChanges
        .pipe(debounceTime(100))
        .subscribe(() => {
          if (this.roundTripForm.valid && this.mustSubmit) {
            this.submitSearch();
          }
        });
  }

  private initOneWayForm() {
    this.oneWayForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      departDate: ['', Validators.required]
    });
    this.oneWayForm.valueChanges
        .pipe(debounceTime(100))
        .subscribe(() => {
          if (this.oneWayForm.valid && this.mustSubmit) {
            this.submitSearch();
          }
        });
  }

  private initMultiCityForm() {
    this.multiCityForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingFrom1: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo1: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingFrom2: [''],
      flyingTo2: [''],
      departDate: ['', Validators.required],
      departDate1: ['', Validators.required],
      departDate2: ['']
    });
    this.multiCityForm.valueChanges
        .pipe(debounceTime(100))
        .subscribe(() => {
          if (this.multiCityForm.valid && this.thirdFlightValid() && this.mustSubmit) {
            this.submitSearch();
          }
        });
  }

  private thirdFlightValid() {
    return this.showThirdFlight
        ? this.showThirdFlight
        && !_.isEmpty(this.multiCityForm.get('flyingFrom2').value)
        && !_.isEmpty(this.multiCityForm.get('flyingTo2').value)
        && !_.isEmpty(this.multiCityForm.get('departDate2').value)
        : true;
  }

  private initQueryPanelForm() {
    this.queryPanelForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([
          Validators.required,
          Validators.pattern('^[(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$'),
          this.phoneValidator()])]
    });
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.phoneSelect && !_.isEmpty(control.value) && control.value.startsWith('(1')
          && this.phoneSelect.getCountryData().iso2 === 'us') {
        return { phoneFormatError: true };
      } else {
        return null;
      }
    };
  }

  private initDatepicker() {
    this.firstDayOfWeek = 7;
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    this.displayMonths = width >= 768 ? 2 : 1;
    this.navigation = 'arrows';
    const now: Date = new Date();
    this.maxDate = prepareDate(now, 3, 'y');
    this.minDateDepart = prepareDate(now);
    if (isEmptyDate(this.flight.departDate)) {
      this.minDateReturn = prepareDate(now, 1, 'd');
      this.minDateDepart1 = prepareDate(now, 1, 'd');
      this.minDateDepart2 = prepareDate(now, 1, 'd');
    }
  }



  private setActiveForm(tripType: any) {
    if (_.eq(tripType, 'Round')) {
      this.activeForm = this.roundTripForm;
    } else if (_.eq(tripType, 'OneWay')) {
      this.activeForm = this.oneWayForm;
    } else {
      this.activeForm = this.multiCityForm;
    }
  }

  private enableButton() {
    this.submitDisabled = false;
    this.submitBtnText = 'Send Me a Quote Now';
  }

  private fillQueryFlightParams() {
    this.setFlights();
  }

  private setFlights() {
    const flightCount: number = this.queryFlightParams.tripType === 'MultiCity' ?
        ((_.isUndefined(this.flight2.from) || _.isEmpty(this.flight2.from)) ? 2 : 3) : 1;
    const flights: Flight[] = new Array(flightCount);
    this.setFromTo(flights, flightCount);
    this.setDepart(flights, flightCount);
    if (this.queryFlightParams.tripType === 'Round') {
      this.setReturnDate();
    }
    this.queryFlightParams.flights = flights;
  }

  private setFromTo(flights: Flight[], flightCount: number) {
    flights[0] = new Flight;
    flights[0].from = this.flight.from;
    flights[0].to = this.flight.to;
    if (flightCount >= 2) {
      flights[1] = new Flight;
      flights[1].from = this.flight1.from;
      flights[1].to = this.flight1.to;
    }
    if (flightCount === 3) {
      flights[2] = new Flight;
      flights[2].from = this.flight2.from;
      flights[2].to = this.flight2.to;
    }
  }

  private setDepart(flights: Flight[], flightCount: number) {
    flights[0].departDate = formatDate(this.flight.departDate);
    if (flightCount >= 2) {
      flights[1].departDate = formatDate(this.flight1.departDate);
    }
    if (flightCount === 3) {
      flights[2].departDate = formatDate(this.flight2.departDate);
    }
  }

  private setReturnDate() {
    this.queryFlightParams.returnDate = formatDate(this.returnDate);
  }

  private disableButton(disable: boolean) {
    this.submitDisabled = disable;
    this.submitBtnText = disable ? 'Loading...' : 'Get Quote';
  }

  setCountry(countryCode) {
    const currentCountry = _.filter(getElementsByClass('country'), function (country) {
      return _.eq(countryCode, country.getAttribute('data-dial-code'));
    });
    if (currentCountry.length > 0) {
      this.phoneSelect.setCountry(currentCountry[0].getAttribute('data-country-code'));
    }
  }

  getCountryData() {
    return this.phoneSelect.getCountryData();
  }

  changeCabinType(cabinType: string) {
    this.queryFlightParams.cabin = cabinType;
    if (this.activeForm.valid) {
      this.submitSearch();
    }
  }

  changeTripType(tripType: string, currentForm) {
    this.queryFlightParams.tripType = tripType;
    this.activeForm = currentForm;
    this.focusOut = false;
    this.clickTripType = true;
  }

  changeTravelers() {
    if (this.activeForm.valid) {
      this.submitSearch();
    }
  }

  /* Close Datepicker */
  onClickedOutsideDp(event, dpId, inputId) {
    if (this.mustClose(event, inputId)) {
      if (_.includes(inputId, 'Depart1')) {
        this.activeDepart1 = false;
      } else if (_.includes(inputId, 'Depart2')) {
        this.activeDepart2 = false;
      } else if (_.includes(inputId, 'Depart')) {
        this.activeDepart = false;
      } else {
        this.activeReturn = false;
      }
      dpId.close();
    }
  }

  private mustClose(event, inputId) {
    let inDatepicker = false;
    _.each(event.target.classList, className => {
      if (_.includes(className, 'ngb') || _.eq(className, 'text-muted')) {
        inDatepicker = true;
      }
    });
    if (getElementById(inputId)) {
      return !(getElementById(inputId).contains(event.target) || inDatepicker);
    } else {
      return false;
    }
  }

  getQuote() {
    if (this.queryPanelForm.get('phone').hasError('phoneFormatError')) {
      this.notifyService.warn('Incorrect area code', {
        closeWith: ['click', 'button'],
        timeout: 5000
      });
    }

    if (!this.activeForm.valid) {
      this.searchFormValid = false;
      this.queryPanelValid = false;
      return;
    }
    if (!this.flightSubmitted) {
      this.submitSearch();
      this.showWarnNoty();
      return;
    }
    if (this.queryPanelForm.valid) {
      this.formSending = true;
      this.disableButton(true);
      this.preparePhone();
      this.searchFormValid = true;
      this.queryPanelValid = true;
      this.contactData.cCode = this.dialCode;
      if (this.bundleSave.addCruise || this.bundleSave.addHotel) {
        this.contactData.comments = `${this.bundleSave.addHotel ? '<p> Add Hotel: true </p>' : ''} ${this.bundleSave.addCruise ? '<p> Add Cruise: true </p>' : ''}`.trim()
      }
      this.trackingService.getTrack().subscribe(() => {
        this.searchboxService.sendRequest(this.contactData).subscribe(
            response => {
              this.formSending = false;
              this.disableButton(false);
              console.debug('Get quote: ' + response.data.details);
              if (_.eq(response.status, 'FAILED')) {
                alert(response.data.details);
              } else {
                /* Lead
                 Track when a user expresses interest in your offering (ex. form submission, sign up for trial, landing on pricing page) */
                // fbq('track', 'Lead');
                GetCustomEvent();
                this.router.navigateByUrl('/your-request');
              }
            },
            error => {
              console.warn('Can\'t process your request now. Please try later.');
              this.disableButton(false);
              this.formSending = false;
            });
      }, () => {
        this.formSending = false;
        this.disableButton(false);
        this.showWarnNoty();
      });
    } else {
      this.queryPanelValid = false;
    }
  }

  private preparePhone() {
    this.contactData.cCode = this.dialCode;
    this.contactData.phone = _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '');
  }

  get dialCode(): string {
    const dialCode = this.phoneSelect.getCountryData().dialCode;
    return !_.isNil(dialCode) && dialCode !== '0' ? dialCode : '1';
  }

  onClickedOutsideTripContent(event) {
    if (_.eq(event.target.className, 'BusinessClassClick') ||
        _.eq(event.target.className, 'traveler') ||
        _.eq(event.target.className, 'fa fa-angle-down')) {
      return;
    }
    this.tripContent = false;
  }

  onClickedOutsideCabinType(event) {
    if (_.eq(event.target.className, 'cabinClick') ||
        _.eq(event.target.className, 'cabinClick ng-star-inserted') ||
        _.eq(event.target.className, 'cabinClick fa fa-angle-down')) {
      return;
    }
    this.cabinContent = false;
  }

  onClickedOutsideTripType(event) {
    if (_.eq(event.target.className, 'tripClick') ||
        _.eq(event.target.className, 'tripClick ng-star-inserted') ||
        _.eq(event.target.className, 'tripClick fa fa-angle-down')) {
      return;
    }
    this.tripTypeContent = false;
  }
  onClickedOutsidePassengers(event) {
    if (_.eq(event.target.className, 'passengersClick') ||
        _.eq(event.target.className, 'passengersClick fa fa-angle-down')) {
      return;
    }
    this.passengersContent = false;
  }


  initReturnDatepicker() {
    if (!isEmptyDate(this.flight.departDate)) {
      let date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
      this.minDateReturn = prepareDate(date, 1, 'd');
      if (!this.initFlightDataNow && this.changedDepartDateModel()) {
        setTimeout(() => this.activeReturn = true, 0);
      }
    }
  }

  changedDepartDateModel(): boolean {
    return _.isEmpty(this.lastFlight.flights) ? true :
        !_.eq(this.lastFlight.flights[0].departDate, formatDate(this.activeForm.get('departDate').value));
  }

  initDepartDatepicker() {
    if (!isEmptyDate(this.flight.departDate)) {
      let date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
      this.minDateDepart1 = prepareDate(date);
    }
    if (!isEmptyDate(this.flight1.departDate)) {
      let date = new Date(this.flight1.departDate.year, this.flight1.departDate.month - 1, this.flight1.departDate.day);
      this.minDateDepart2 = prepareDate(date);
    }
  }

  openHowItWorksDialog(): void {
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef = this.modalService.open(HowItWorksDialogComponent, {
      windowClass: 'hiw-modal',
      backdropClass: 'hiw-backdrop'
    }).result.then((result) => {
      this.dataService.sendData({type: 'showModal', data: false});
    }, (reason) => {
      this.dataService.sendData({type: 'showModal', data: false});
    });


    // dialogRef.close().subscribe(result => {
    //
    //   console.trace('The dialog was closed');
    // });
  }

  @HostListener('document:click')
  handleClick() {
    if (isPlatformBrowser(this.platformId)) {
      fitCountrySelect();
      this.initFlightDataNow = false;
      this.clickTripType = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.isMobileOrTablet) {
      this.phoneSelect.el.nativeElement.previousElementSibling.style.pointerEvents = 'none';
    }
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.submitSearch();
        fitCountrySelect();
        /* Set contact info */
        this.searchboxService.getInitContactData().subscribe(response => {
          if (!_.isNull(response.data.contactInfo.email)) {
            this.contactData = response.data.contactInfo;
            this.contactData.comments = '';
            const splittedPhone = _.split(response.data.contactInfo.phone, ' ');
            if (splittedPhone.length > 1) {
              this.contactData.phone = splittedPhone[1].replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
              this.setCountry(_.replace(splittedPhone[0], '+', ''));
            }
          }
        });
      });
    }
  }

  addFlight(): void {
    this.showAddFlightButton = false;
    this.showThirdFlight = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onFocusOutAirportInput(event): void {
    if (_.isNil(event.relatedTarget) || (!_.isNil(event.relatedTarget) && !_.includes(event.relatedTarget.className, 'mat-option'))) {
      this.changeMustSubmit(true);
      if (this.formSending === false) {
        this.submitSearch();
      }
    }
  }

  jumpToNextControl(element, autocompleteId): void {
    if (!this.isMobileOrTablet) {
      if (_.eq(autocompleteId, 'flyingToRound') || _.eq(autocompleteId, 'flyingToOneWay') || _.eq(autocompleteId, 'flyingToMultiCity')) {
        setTimeout(() => this.activeDepart = true, 0);
      } else if (_.eq(autocompleteId, 'flyingTo1MultiCity')) {
        setTimeout(() => this.activeDepart1 = true, 0);
      } else if (_.eq(autocompleteId, 'flyingTo2MultiCity')) {
        setTimeout(() => this.activeDepart2 = true, 0);
      }
      this.changeMustSubmit(true);
      if (element && !this.initFlightDataNow && !this.clickTripType) {
        if (element instanceof HTMLInputElement) {
          this.setTimeOut(element);
        } else {
          const nativeElement = element._elRef.nativeElement;
          if (element && (_.includes(nativeElement.id, 'Return') || _.includes(nativeElement.id, 'Depart'))) {
            setTimeout(() => {
              let currentAutocomplete = getElementById(autocompleteId);
              if (currentAutocomplete) {
                currentAutocomplete.blur();
              }
              element.toggle();
            }, 100);
          }
        }
      }
    }
  }

  setTimeOut(element) {
    setTimeout(() => {
      if (this.formSending) {
        interval(10)
            .pipe(takeWhile(() => this.formSending))
            .subscribe(() => {
              setTimeout(() => element.click(), 100);
              // This will be called every 10 milliseconds until `formSending` flag is set to false
            });
      } else {
        setTimeout(() => element.click(), 100);
      }
    }, 100);
  }

  capitalize(value: string) {
    return _.capitalize(value);
  }


  onBlur(id: string) {
    const blurredElement = getElementById(id);
    if (_.isNil(blurredElement)) {
      return;
    }
    setTimeout(() => blurredElement.blur(), 0);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'isEmpty': true };
  }

  get  withForm(): boolean {
    return !this.router.url.includes('faq') &&
        !this.router.url.includes('about-us') &&
        !this.router.url.includes('terms-and-conditions') &&
        !this.router.url.includes('terms-and-conditions-app') &&
        !this.router.url.includes('insurance-terms') &&
        !this.router.url.includes('privacy-policy') &&
        !this.router.url.includes('corporate') &&
        !this.router.url.includes('reviews') &&
        !this.router.url.includes('our-team') &&
        !this.router.url.includes('your-request') &&
        !this.router.url.includes('privacy-app') &&
        !this.router.url.includes('contact-us') &&
        !this.router.url.includes('app-page');
  }

  public openMobileFlyingFromSelect(control: FormControl, event: MouseEvent, isFlyingFrom: boolean = false): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(FlyingFromDialogComponent, {
        windowClass: 'flying-from-modal',
        backdropClass: 'hiw-backdrop'
      });
      dialogRef.componentInstance.isFlyingFrom = isFlyingFrom;
      if (control.value) dialogRef.componentInstance.selectedAirport = transformToSquareBrackets(control.value);

      dialogRef.result.then((result): void => {
        (event.target as HTMLInputElement).blur();
        if (result) {
          control.setValue(transformToRoundBrackets(result));
        }
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason): void => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public getControlName(c: FormControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  public openMobileFromToDialog(fromControl: FormControl, toControl: FormControl, event: MouseEvent, isFlyingFrom: boolean = false, nextStepControlName: string = null): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(FlyingCombinedDirectionDialogComponent, {
        windowClass: 'flying-from-modal',
        backdropClass: 'hiw-backdrop'
      });
      dialogRef.componentInstance.isFlyingFrom = isFlyingFrom;
      dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
      if (fromControl.value) dialogRef.componentInstance.formDepartureValue = transformToSquareBrackets(fromControl.value);
      if (toControl.value) dialogRef.componentInstance.formDestinationValue = transformToSquareBrackets(toControl.value);

      dialogRef.result.then((result): void => {
        if (event) (event.target as HTMLInputElement).blur();

        if (result) {
          fromControl.setValue(transformToRoundBrackets(result.from));
          toControl.setValue(transformToRoundBrackets(result.to));

          if (this.activeForm === this.roundTripForm) {
            this.openMobileRoundTripDateDialog((this.roundTripForm.get('departDate') as FormControl), null);
          } else if (this.activeForm === this.oneWayForm) {
            this.openMobileDepartReturnDateDialog((this.oneWayForm.get('departDate') as FormControl), null, true);
          } else if (this.activeForm === this.multiCityForm) {
            this.openMobileDepartReturnDateDialog((this.multiCityForm.get(nextStepControlName) as FormControl), null, true);
          }
        }
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason): void => {
        if (this.getControlName(fromControl).includes('flyingFrom1')) {
          this.openMobileDepartReturnDateDialog(this.multiCityForm.get('departDate') as FormControl, null, true);
        } else if (this.getControlName(fromControl).includes('flyingFrom2')) {
          this.openMobileDepartReturnDateDialog(this.multiCityForm.get('departDate1') as FormControl, null, true);
        }

        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openMobileFlyingToSelect(): void {
    // todo should check and delete
    // todo flying from/to captions
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(FlyingToDialogComponent, {
        windowClass: 'flyuing-to-modal',
        backdropClass: 'hiw-backdrop'
      }).result.then((result) => {
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openMobileRoundTripDateDialog(control: FormControl, event: MouseEvent, isDepart: boolean = false): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(RoundTripDateDialogComponent, {
        windowClass: 'date-depart-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.componentInstance.isDepart = isDepart;
      if (control.value) {
        dialogRef.componentInstance.selectedValue = control.value;
      }

      if (this.roundTripForm.get('departDate').value && this.roundTripForm.get('departDate').value.year) {
        dialogRef.componentInstance.startDay = this.roundTripForm.get('departDate').value;
      }

      if (this.roundTripForm.get('returnDate').value && this.roundTripForm.get('returnDate').value.year) {
        dialogRef.componentInstance.endDay = this.roundTripForm.get('returnDate').value;
      }

      dialogRef.result.then((result) => {
        if (result) {
          this.roundTripForm.get('departDate').setValue(result.startDay);
          this.roundTripForm.get('returnDate').setValue(result.endDay);
        }

        if (event) {
          (event.target as HTMLInputElement).blur();
        }

        this.dataService.sendData({type: 'showModal', data: false});

        if (result) {
          this.openContactMobileDialog(null);
        }
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
        this.openMobileFromToDialog((this.roundTripForm.get('flyingFrom') as FormControl), (this.roundTripForm.get('flyingTo') as FormControl), null, true);
      });
    }
  }

  public openMobileDepartReturnDateDialog(control: FormControl, event: MouseEvent, isDepart: boolean = false): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(DepartReturnDateDialogComponent, {
        windowClass: 'date-depart-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.componentInstance.isDepart = isDepart;
      dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
      if (control.value) {
        dialogRef.componentInstance.selectedValue = control.value;
      }

      dialogRef.result.then((result) => {
        if (result) {
          control.setValue(result);

          if (this.activeForm === this.oneWayForm) {
            this.openContactMobileDialog(null);
          }
        }

        if (event) {
          (event.target as HTMLInputElement).blur();
        }

        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        if (this.activeForm === this.oneWayForm) {
          this.openMobileFromToDialog((this.oneWayForm.get('flyingFrom') as FormControl), (this.oneWayForm.get('flyingTo') as FormControl), null, true);
        } else if (this.activeForm === this.multiCityForm) {
          if (this.getControlName(control).includes('2')) {
            this.openMobileFromToDialog(this.multiCityForm.get('flyingFrom2') as FormControl, this.multiCityForm.get('flyingTo2') as FormControl,
              null, true, 'departDate2');
          } else if (this.getControlName(control).includes('1')) {
            this.openMobileFromToDialog(this.multiCityForm.get('flyingFrom1') as FormControl, this.multiCityForm.get('flyingTo1') as FormControl,
              null, false, 'departDate1');
          } else {
            this.openMobileFromToDialog(this.multiCityForm.get('flyingFrom') as FormControl, this.multiCityForm.get('flyingTo') as FormControl,
              null, false, 'departDate');
          }
        }
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openFlightOptionsDialog(): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.searchboxFlightsMobileService.setFlightOptions({
        tripType: this.queryFlightParams.tripType,
        passengers: this.queryFlightParams.passengers,
        cabin: this.queryFlightParams.cabin
      });
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(FlightOptionsDialogComponent, {
        windowClass: 'date-return-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.result.then((result) => {
        const data: FlightOptions = this.flightMobileQuoteData.queryCruiseParams;
        this.queryFlightParams.cabin = data.cabin;
        this.queryFlightParams.tripType = data.tripType;
        this.queryFlightParams.passengers = data.passengers;
        this.focusOut = false;
        this.clickTripType = true;

        if (this.queryFlightParams.tripType === 'Round') {
          this.activeForm = this.roundTripForm;
        } else if (this.queryFlightParams.tripType === 'OneWay') {
          this.activeForm = this.oneWayForm;
        } else if (this.queryFlightParams.tripType === 'MultiCity') {
          this.activeForm = this.multiCityForm;
        }

        if (this.activeForm.valid) {
          this.submitSearch();
        }
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public checkContactOpeningSource(event: MouseEvent): void {
    if (this.isMobileOrTablet) {
      if (this.activeForm === this.roundTripForm) {
        this.openMobileFromToDialog(this.roundTripForm.get('flyingFrom') as FormControl, this.roundTripForm.get('flyingTo') as FormControl, null, true);

        return
      } else if (this.activeForm === this.oneWayForm) {
        this.openMobileFromToDialog(this.oneWayForm.get('flyingFrom') as FormControl, this.oneWayForm.get('flyingTo') as FormControl, null, true);

        return;
      } else {
        this.openContactMobileDialog(event);
      }
    }
  }

  public openContactMobileDialog(event: MouseEvent): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef: NgbModalRef = this.modalService.open(TravelerNameDialogComponent, {
        windowClass: 'traveler-name-modal',
        backdropClass: 'hiw-backdrop'
      });

      dialogRef.componentInstance.searchboxContactData = {
        cCode: '1',
        firstName: this.contactData.firstName,
        email: this.contactData.email,
        phone: _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '')
      };

      dialogRef.componentInstance.searchboxActiveForm = this.activeForm;
      dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
      dialogRef.componentInstance.isOneWayForm = this.activeForm === this.oneWayForm;
      dialogRef.componentInstance.isMultiCityValid = this.activeForm === this.multiCityForm && this.thirdFlightValid();
      dialogRef.componentInstance.showThirdFlight = this.showThirdFlight;
      dialogRef.componentInstance.flightOptions = {
        travelers: this.queryFlightParams.passengers,
        cabin: this.queryFlightParams.cabin
      };

      dialogRef.result.then((result) => {
        if (event) {
          (event.target as HTMLInputElement).blur();
        }

        this.dataService.sendData({type: 'showModal', data: false});
        Object.keys(result.contactData).forEach((item: string) => {
          if (!_.isNil(result.contactData[item])) {
            this.contactData[item] = result.contactData[item];
          }
        });
        if (this.contactData.phone && this.contactData.phone.length > 1) {
          this.contactData.phone = this.contactData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }

        if (result.isSuccess) {
          this.openFinalSuccessDialog();
        }

        if (result.moveBack) {
          if (this.activeForm === this.roundTripForm) {
            this.openMobileRoundTripDateDialog((this.roundTripForm.get('departDate') as FormControl), null);
          } else if (this.activeForm === this.oneWayForm) {
            this.openMobileDepartReturnDateDialog((this.oneWayForm.get('departDate') as FormControl), null, true);
          }
        }
        }, (reason) => {
          this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openNameDialog(): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(TravelerNameDialogComponent, {
        windowClass: 'traveler-name-modal',
        backdropClass: 'hiw-backdrop'
      }).result.then((result) => {
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openFinalSuccessDialog(): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(TravelerPhoneDialogComponent, {
        windowClass: 'traveler-phone-modal',
        backdropClass: 'hiw-backdrop'
      }).result.then((result) => {
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openEmailDialog(): void {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.dataService.sendData({type: 'showModal', data: true});
      const dialogRef = this.modalService.open(TravelerEmailDialogComponent, {
        windowClass: 'traveler-email-modal',
        backdropClass: 'hiw-backdrop'
      }).result.then((result) => {
        this.dataService.sendData({type: 'showModal', data: false});
      }, (reason) => {
        this.dataService.sendData({type: 'showModal', data: false});
      });
    }
  }

  public openBundleSaveDialog(): void {
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef = this.modalService.open(BundleSaveDialogComponent, {
      windowClass: 'bundle-save-modal',
      backdropClass: 'hiw-backdrop'
    }).result.then((result) => {
      this.dataService.sendData({type: 'showModal', data: false});
    }, (reason) => {
      this.dataService.sendData({type: 'showModal', data: false});
    });
  }

  public openTripTypeMobile(): void {
    if (this.isMobileOrTablet) {
      this.openFlightOptionsDialog();
    } else {
      this.tripTypeContent = !this.tripTypeContent;
    }
  }

  public openCabinTypeMobile(): void {
    if (this.isMobileOrTablet) {
      this.openFlightOptionsDialog();
    } else {
      this.cabinContent = !this.cabinContent;
    }
  }

  public openTravelersMobile(): void {
    if (this.isMobileOrTablet) {
      this.openFlightOptionsDialog();
    } else {
      this.passengersContent = !this.passengersContent;
    }
  }

  public getDatePlaceholder(dateObject: NgbDateStruct, basePlaceholder: string): string {
    if (this.isMobile && dateObject && dateObject.year) {
      const date: Date = new Date(dateObject.year, dateObject.month - 1, dateObject.day);
      // todo check desktop version
      // todo check ipad mini(tablet)
      // todo check type of data from server
      return moment(date).format('ddd MMM D');
    } else {
      return basePlaceholder;
    }
  }

  public getFromToPlaceholder(flyingData: string, basePlaceholder: string): string {
    if (this.isMobile && flyingData) {
      // todo check desktop version
      // todo check ipad mini(tablet)
      // todo check type of data from server
      return this.removeBrackets(flyingData);
    } else {
      return basePlaceholder;
    }
  }

  public displayFn(value?: string): string {
    if (this.isMobile && value) {
      return extractAirportCodeFromBrackets(value);
    } else {
      return value;
    }
  }

  public countryChanged(): void {
    this.queryPanelForm.get('phone').updateValueAndValidity();
  }
}
