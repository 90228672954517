import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QueryFlightParams } from '../../../model/query-flight-params';
import * as _ from 'lodash';
import { Flight } from '../../../model/flight';
import { ContactData } from '../../../model/contact-data';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { HowItWorksDialogComponent } from '../../modal/how-it-works-dialog/how-it-works-dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '../../../../shared/shared-data.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import 'rxjs/Rx';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';
import { formatDate, isEmptyDate, prepareDate } from '../../../../shared/util/date-util';
import { phoneMask } from '../../../app.constants';
import { getElementById, fitCountrySelect, getElementsByClass } from '../../../../shared/util/DOM-util';
import { NotifyService } from '../../../services/notify.service';
import { TrackingService } from '../../../services/tracking.service';
import { debounceTime, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import { SearchboxService } from '../searchbox/searchbox.service';
import { FlyingFromDialogComponent } from '../../modal/flying-from-dialog/flying-from-dialog.component';
import { FlyingToDialogComponent } from '../../modal/flying-to-dialog/flying-to-dialog.component';
import { RoundTripDateDialogComponent } from '../../modal/round-trip-date-dialog/round-trip-date-dialog.component';
import { FlightOptionsDialogComponent } from '../../modal/flight-options-dialog/flight-options-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TravelerNameDialogComponent } from '../../modal/traveler-name-dialog/traveler-name-dialog.component';
import { TravelerPhoneDialogComponent } from '../../modal/traveler-phone-dialog/traveler-phone-dialog.component';
import { TravelerEmailDialogComponent } from '../../modal/traveler-email-dialog/traveler-email-dialog.component';
import { BundleSaveDialogComponent } from '../../modal/bundle-save-dialog/bundle-save-dialog.component';
import { SearchboxFlightsMobileService } from '../../../services/searchbox-flights-mobile.service';
import { extractAirportCodeFromBrackets, removeBrackets, transformToRoundBrackets, transformToSquareBrackets } from '../../../model/transform-brackets';
import { DepartReturnDateDialogComponent } from '../../modal/depart-return-date-dialog/depart-return-date-dialog.component';
import * as moment from 'moment';
import { FlyingCombinedDirectionDialogComponent } from '../../modal/flying-combined-direction-dialog/flying-combined-direction-dialog.component';
import { ContactFieldsListEnum } from '../../../model/contact-fields-list.enum';
import { ReCaptchaV3Service } from "ng-recaptcha";
var PHONE_KEY = makeStateKey('phone');
var PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');
var SearchboxNewGenerationComponent = /** @class */ (function () {
    function SearchboxNewGenerationComponent(formBuilder, searchboxService, cookieService, route, platformId, router, dataService, state, appStorage, trackingService, notifyService, modalService, deviceService, searchboxFlightsMobileService, recaptchaV3Service) {
        this.formBuilder = formBuilder;
        this.searchboxService = searchboxService;
        this.cookieService = cookieService;
        this.route = route;
        this.platformId = platformId;
        this.router = router;
        this.dataService = dataService;
        this.state = state;
        this.appStorage = appStorage;
        this.trackingService = trackingService;
        this.notifyService = notifyService;
        this.modalService = modalService;
        this.deviceService = deviceService;
        this.searchboxFlightsMobileService = searchboxFlightsMobileService;
        this.recaptchaV3Service = recaptchaV3Service;
        this.activeForm = this.roundTripForm;
        this.queryFlightParams = new QueryFlightParams;
        this.flight = new Flight;
        this.flight1 = new Flight;
        this.flight2 = new Flight;
        this.locationData = [];
        this.formSending = false;
        this.contactData = new ContactData();
        this.phoneMask = phoneMask;
        this.showAddFlightButton = true;
        this.showThirdFlight = false;
        this.focusOut = false;
        this.mustSubmit = false;
        this.phoneVisible = false;
        this.lastFlight = new QueryFlightParams;
        this.clickTripType = true;
        this.flightSubmitted = false;
        this.removeBrackets = removeBrackets;
        this.contactFieldsListEnum = ContactFieldsListEnum;
        this.bundleSave = {
            addCruise: null,
            addHotel: null,
        };
        this.destroy$ = new Subject();
        this.flightMobileQuoteData = this.searchboxFlightsMobileService.getFlightQuoteData();
    }
    SearchboxNewGenerationComponent.prototype.ngOnInit = function () {
        this.initPhone();
        this.isMobileOrTablet = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.isMobile = this.deviceService.isMobile();
        this.normalFlight = true;
        this.searchFormValid = true;
        this.queryPanelValid = true;
        this.initTripContent();
        this.queryFlightParams.tripType = 'Round';
        this.submitBtnText = 'Get Quote';
        this.initForms();
        this.initFields();
        this.initAutocompleteForAll();
    };
    SearchboxNewGenerationComponent.prototype.executeRecaptchaCheckAction = function () {
        var _this = this;
        this.recaptchaV3Service.execute('submit')
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (token) {
            if (token) {
                _this.getQuote();
            }
        });
    };
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "landingClass", {
        get: function () {
            return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    SearchboxNewGenerationComponent.prototype.routeTo = function (path) {
        var _this = this;
        setTimeout(function () { _this.router.navigateByUrl(path); }, 0);
    };
    SearchboxNewGenerationComponent.prototype.changeMustSubmit = function (mustSubmit) {
        this.mustSubmit = mustSubmit;
    };
    SearchboxNewGenerationComponent.prototype.initFields = function () {
        var _this = this;
        this.searchboxService.getInitQueryFlightParams().subscribe(function (response) {
            _this.queryFlightParams = response;
            if (_.eq(response.cabin, 'BLANK')) {
                _this.queryFlightParams.cabin = 'BUSINESS';
            }
            _this.flight.from = response.flights[0].from;
            _this.flight.to = response.flights[0].to;
            _this.flight.departDate = prepareDate(response.flights[0].departDate);
            _this.returnDate = prepareDate(response.returnDate);
            _this.minDateReturn = prepareDate(response.flights[0].departDate, 1, 'd');
            if (response.flights.length > 1) {
                _this.flight1.from = response.flights[1].from;
                _this.flight1.to = response.flights[1].to;
                _this.flight1.departDate = prepareDate(response.flights[1].departDate);
                _this.minDateDepart1 = prepareDate(response.flights[0].departDate);
            }
            if (response.flights.length === 3) {
                _this.flight2.from = response.flights[2].from;
                _this.flight2.to = response.flights[2].to;
                _this.flight2.departDate = prepareDate(response.flights[2].departDate);
                _this.minDateDepart2 = prepareDate(response.flights[1].departDate);
                _this.showThirdFlight = true;
                _this.showAddFlightButton = false;
            }
            _this.setActiveForm(response.tripType);
            _this.initFlightDataNow = true;
            if (isPlatformBrowser(_this.platformId)) {
                _this.initDatepicker();
            }
        }, function (error) {
            if (isPlatformBrowser(_this.platformId)) {
                _this.initDatepicker();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.submitSearch = function () {
        if (this.activeForm === this.multiCityForm && !this.thirdFlightValid()) {
            return;
        }
        if (this.activeForm.valid && isPlatformBrowser(this.platformId)) {
            this.submit();
        }
    };
    SearchboxNewGenerationComponent.prototype.submit = function () {
        var _this = this;
        this.fillQueryFlightParams();
        if (!_.isUndefined(this.queryFlightParams.cabin) && !_.isUndefined(this.queryFlightParams.passengers)) {
            if (this.modelChanged()) {
                this.formSending = true;
                this.disableButton(true);
                this.trackingService.getTrack().subscribe(function () {
                    _this.searchboxService.submitSearch(_this.queryFlightParams).subscribe(function (response) {
                        _this.formSending = false;
                        _this.disableButton(false);
                        if (!_.isNil(response.data.error)) {
                            alert(response.data.error);
                        }
                        else {
                            // We lost dial code after contact form hid, to prevent it store code before (WSF-1766)
                            if (_this.normalFlight === true && response.data.normal === false) {
                                _this.contactData.cCode = _this.dialCode;
                            }
                            _this.normalFlight = response.data.normal;
                            // restore dial code (WSF-1766)
                            setTimeout(function () {
                                if (_this.phoneSelect) {
                                    if (_this.isMobileOrTablet) {
                                        if (!Object.keys(_this.phoneSelect.getCountryData()).length) {
                                            _this.phoneSelect.setCountry('us');
                                            _this.phoneSelect.el.nativeElement.previousElementSibling.style.pointerEvents = 'none';
                                            _this.contactData.cCode = '1';
                                        }
                                    }
                                    else {
                                        _this.setCountry(_this.contactData.cCode);
                                    }
                                }
                            });
                            _this.flightSubmitted = true;
                            _this.lastFlight = _.clone(_this.queryFlightParams);
                        }
                    }, function (error) {
                        _this.formSending = false;
                        _this.disableButton(false);
                    });
                }, function () {
                    _this.formSending = false;
                    _this.enableButton();
                    _this.showWarnNoty();
                });
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.showWarnNoty = function () {
        this.notifyService.warn('Can\'t process your request now. Please try later.', {
            closeWith: ['click', 'button'],
            timeout: 10000
        });
    };
    SearchboxNewGenerationComponent.prototype.modelChanged = function () {
        return !_.isEqual(this.queryFlightParams, this.lastFlight);
    };
    SearchboxNewGenerationComponent.prototype.autoFillFromParam = function (name) {
        var _this = this;
        if (_.isNil(name) || _.isEmpty(name)) {
            return;
        }
        this.searchboxService.getLocations(name.replace('-', ' ')).subscribe(function (response) {
            if (response.length !== 0) {
                _this.flight.to = response[0];
            }
        }, function (error) {
            //    do nothing
        });
    };
    SearchboxNewGenerationComponent.prototype.initPhone = function () {
        var _this = this;
        if (!_.isNil(this.state.get(PHONE_KEY, null))) {
            this.phone = this.state.get(PHONE_KEY, null);
        }
        if (!_.isNil(this.state.get(PHONE_VISIBLE_KEY, null))) {
            this.phoneVisible = this.state.get(PHONE_VISIBLE_KEY, null);
        }
        this.subscription = this.dataService.getData()
            .subscribe(function (sharedData) {
            if (_.eq(sharedData.type, 'phone')) {
                _this.phone = sharedData.data;
            }
            if (_.eq(sharedData.type, 'phoneVisible')) {
                _this.phoneVisible = sharedData.data;
            }
            if (_.eq(sharedData.type, 'autoFillTo')) {
                _this.autoFillFromParam(sharedData.data);
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.initTripContent = function () {
        this.queryFlightParams.cabin = 'BUSINESS';
        if (!_.isEmpty(this.appStorage.getItem('WF_CABIN_TYPE'))) {
            this.queryFlightParams.cabin = this.appStorage.getItem('WF_CABIN_TYPE');
        }
        this.queryFlightParams.passengers = 1;
        if (!_.isEmpty(this.appStorage.getItem('WF_ADULT'))) {
            this.queryFlightParams.passengers = Number(this.appStorage.getItem('WF_ADULT'));
        }
    };
    /* Start init autocomplete */
    SearchboxNewGenerationComponent.prototype.initAutocompleteForAll = function () {
        /* Init autocomplete for round trip */
        this.filteredFromData = this.initAutocomplete(this.roundTripForm, 'flyingFrom');
        this.filteredToData = this.initAutocomplete(this.roundTripForm, 'flyingTo');
        /* Init autocomplete for one way */
        this.filteredFromOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingFrom');
        this.filteredToOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingTo');
        /* Init autocomplete for multi city */
        this.filteredFromMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom');
        this.filteredToMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo');
        this.filteredFrom1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom1');
        this.filteredTo1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo1');
        this.filteredFrom2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom2');
        this.filteredTo2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo2');
    };
    SearchboxNewGenerationComponent.prototype.initAutocomplete = function (form, contrl) {
        var _this = this;
        return form.get(contrl).valueChanges
            .pipe(debounceTime(200), switchMap(function (value) {
            if (!_this.mustSubmit && !_.isEmpty(value) && _.isNil(value.match(/\D+\(\w+\)/i))) {
                return _this.searchboxService.getLocations(value.trim())
                    .pipe(map(function (response) {
                    if (response.length === 0 && !_.isNull(value) && !_.isEmpty(value)) {
                        return Array.of('No cities found');
                    }
                    else {
                        return response;
                    }
                }));
            }
            else {
                return [];
            }
        }));
    };
    /* End init autocomplete */
    SearchboxNewGenerationComponent.prototype.initForms = function () {
        this.initRoundTripForm();
        this.initOneWayForm();
        this.initMultiCityForm();
        this.initQueryPanelForm();
        this.activeForm = this.roundTripForm;
    };
    SearchboxNewGenerationComponent.prototype.initRoundTripForm = function () {
        var _this = this;
        this.roundTripForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            departDate: ['', Validators.required],
            returnDate: ['', Validators.required]
        });
        this.roundTripForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function () {
            if (_this.roundTripForm.valid && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.initOneWayForm = function () {
        var _this = this;
        this.oneWayForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            departDate: ['', Validators.required]
        });
        this.oneWayForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function () {
            if (_this.oneWayForm.valid && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.initMultiCityForm = function () {
        var _this = this;
        this.multiCityForm = this.formBuilder.group({
            flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingFrom1: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingTo1: ['', [Validators.required, this.noWhitespaceValidator]],
            flyingFrom2: [''],
            flyingTo2: [''],
            departDate: ['', Validators.required],
            departDate1: ['', Validators.required],
            departDate2: ['']
        });
        this.multiCityForm.valueChanges
            .pipe(debounceTime(100))
            .subscribe(function () {
            if (_this.multiCityForm.valid && _this.thirdFlightValid() && _this.mustSubmit) {
                _this.submitSearch();
            }
        });
    };
    SearchboxNewGenerationComponent.prototype.thirdFlightValid = function () {
        return this.showThirdFlight
            ? this.showThirdFlight
                && !_.isEmpty(this.multiCityForm.get('flyingFrom2').value)
                && !_.isEmpty(this.multiCityForm.get('flyingTo2').value)
                && !_.isEmpty(this.multiCityForm.get('departDate2').value)
            : true;
    };
    SearchboxNewGenerationComponent.prototype.initQueryPanelForm = function () {
        this.queryPanelForm = this.formBuilder.group({
            firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            phone: ['', Validators.compose([
                    Validators.required,
                    Validators.pattern('^[(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$'),
                    this.phoneValidator()
                ])]
        });
    };
    SearchboxNewGenerationComponent.prototype.phoneValidator = function () {
        var _this = this;
        return function (control) {
            if (_this.phoneSelect && !_.isEmpty(control.value) && control.value.startsWith('(1')
                && _this.phoneSelect.getCountryData().iso2 === 'us') {
                return { phoneFormatError: true };
            }
            else {
                return null;
            }
        };
    };
    SearchboxNewGenerationComponent.prototype.initDatepicker = function () {
        this.firstDayOfWeek = 7;
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        this.displayMonths = width >= 768 ? 2 : 1;
        this.navigation = 'arrows';
        var now = new Date();
        this.maxDate = prepareDate(now, 3, 'y');
        this.minDateDepart = prepareDate(now);
        if (isEmptyDate(this.flight.departDate)) {
            this.minDateReturn = prepareDate(now, 1, 'd');
            this.minDateDepart1 = prepareDate(now, 1, 'd');
            this.minDateDepart2 = prepareDate(now, 1, 'd');
        }
    };
    SearchboxNewGenerationComponent.prototype.setActiveForm = function (tripType) {
        if (_.eq(tripType, 'Round')) {
            this.activeForm = this.roundTripForm;
        }
        else if (_.eq(tripType, 'OneWay')) {
            this.activeForm = this.oneWayForm;
        }
        else {
            this.activeForm = this.multiCityForm;
        }
    };
    SearchboxNewGenerationComponent.prototype.enableButton = function () {
        this.submitDisabled = false;
        this.submitBtnText = 'Send Me a Quote Now';
    };
    SearchboxNewGenerationComponent.prototype.fillQueryFlightParams = function () {
        this.setFlights();
    };
    SearchboxNewGenerationComponent.prototype.setFlights = function () {
        var flightCount = this.queryFlightParams.tripType === 'MultiCity' ?
            ((_.isUndefined(this.flight2.from) || _.isEmpty(this.flight2.from)) ? 2 : 3) : 1;
        var flights = new Array(flightCount);
        this.setFromTo(flights, flightCount);
        this.setDepart(flights, flightCount);
        if (this.queryFlightParams.tripType === 'Round') {
            this.setReturnDate();
        }
        this.queryFlightParams.flights = flights;
    };
    SearchboxNewGenerationComponent.prototype.setFromTo = function (flights, flightCount) {
        flights[0] = new Flight;
        flights[0].from = this.flight.from;
        flights[0].to = this.flight.to;
        if (flightCount >= 2) {
            flights[1] = new Flight;
            flights[1].from = this.flight1.from;
            flights[1].to = this.flight1.to;
        }
        if (flightCount === 3) {
            flights[2] = new Flight;
            flights[2].from = this.flight2.from;
            flights[2].to = this.flight2.to;
        }
    };
    SearchboxNewGenerationComponent.prototype.setDepart = function (flights, flightCount) {
        flights[0].departDate = formatDate(this.flight.departDate);
        if (flightCount >= 2) {
            flights[1].departDate = formatDate(this.flight1.departDate);
        }
        if (flightCount === 3) {
            flights[2].departDate = formatDate(this.flight2.departDate);
        }
    };
    SearchboxNewGenerationComponent.prototype.setReturnDate = function () {
        this.queryFlightParams.returnDate = formatDate(this.returnDate);
    };
    SearchboxNewGenerationComponent.prototype.disableButton = function (disable) {
        this.submitDisabled = disable;
        this.submitBtnText = disable ? 'Loading...' : 'Get Quote';
    };
    SearchboxNewGenerationComponent.prototype.setCountry = function (countryCode) {
        var currentCountry = _.filter(getElementsByClass('country'), function (country) {
            return _.eq(countryCode, country.getAttribute('data-dial-code'));
        });
        if (currentCountry.length > 0) {
            this.phoneSelect.setCountry(currentCountry[0].getAttribute('data-country-code'));
        }
    };
    SearchboxNewGenerationComponent.prototype.getCountryData = function () {
        return this.phoneSelect.getCountryData();
    };
    SearchboxNewGenerationComponent.prototype.changeCabinType = function (cabinType) {
        this.queryFlightParams.cabin = cabinType;
        if (this.activeForm.valid) {
            this.submitSearch();
        }
    };
    SearchboxNewGenerationComponent.prototype.changeTripType = function (tripType, currentForm) {
        this.queryFlightParams.tripType = tripType;
        this.activeForm = currentForm;
        this.focusOut = false;
        this.clickTripType = true;
    };
    SearchboxNewGenerationComponent.prototype.changeTravelers = function () {
        if (this.activeForm.valid) {
            this.submitSearch();
        }
    };
    /* Close Datepicker */
    SearchboxNewGenerationComponent.prototype.onClickedOutsideDp = function (event, dpId, inputId) {
        if (this.mustClose(event, inputId)) {
            if (_.includes(inputId, 'Depart1')) {
                this.activeDepart1 = false;
            }
            else if (_.includes(inputId, 'Depart2')) {
                this.activeDepart2 = false;
            }
            else if (_.includes(inputId, 'Depart')) {
                this.activeDepart = false;
            }
            else {
                this.activeReturn = false;
            }
            dpId.close();
        }
    };
    SearchboxNewGenerationComponent.prototype.mustClose = function (event, inputId) {
        var inDatepicker = false;
        _.each(event.target.classList, function (className) {
            if (_.includes(className, 'ngb') || _.eq(className, 'text-muted')) {
                inDatepicker = true;
            }
        });
        if (getElementById(inputId)) {
            return !(getElementById(inputId).contains(event.target) || inDatepicker);
        }
        else {
            return false;
        }
    };
    SearchboxNewGenerationComponent.prototype.getQuote = function () {
        var _this = this;
        if (this.queryPanelForm.get('phone').hasError('phoneFormatError')) {
            this.notifyService.warn('Incorrect area code', {
                closeWith: ['click', 'button'],
                timeout: 5000
            });
        }
        if (!this.activeForm.valid) {
            this.searchFormValid = false;
            this.queryPanelValid = false;
            return;
        }
        if (!this.flightSubmitted) {
            this.submitSearch();
            this.showWarnNoty();
            return;
        }
        if (this.queryPanelForm.valid) {
            this.formSending = true;
            this.disableButton(true);
            this.preparePhone();
            this.searchFormValid = true;
            this.queryPanelValid = true;
            this.contactData.cCode = this.dialCode;
            if (this.bundleSave.addCruise || this.bundleSave.addHotel) {
                this.contactData.comments = ((this.bundleSave.addHotel ? '<p> Add Hotel: true </p>' : '') + " " + (this.bundleSave.addCruise ? '<p> Add Cruise: true </p>' : '')).trim();
            }
            this.trackingService.getTrack().subscribe(function () {
                _this.searchboxService.sendRequest(_this.contactData).subscribe(function (response) {
                    _this.formSending = false;
                    _this.disableButton(false);
                    console.debug('Get quote: ' + response.data.details);
                    if (_.eq(response.status, 'FAILED')) {
                        alert(response.data.details);
                    }
                    else {
                        /* Lead
                         Track when a user expresses interest in your offering (ex. form submission, sign up for trial, landing on pricing page) */
                        // fbq('track', 'Lead');
                        GetCustomEvent();
                        _this.router.navigateByUrl('/your-request');
                    }
                }, function (error) {
                    console.warn('Can\'t process your request now. Please try later.');
                    _this.disableButton(false);
                    _this.formSending = false;
                });
            }, function () {
                _this.formSending = false;
                _this.disableButton(false);
                _this.showWarnNoty();
            });
        }
        else {
            this.queryPanelValid = false;
        }
    };
    SearchboxNewGenerationComponent.prototype.preparePhone = function () {
        this.contactData.cCode = this.dialCode;
        this.contactData.phone = _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '');
    };
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "dialCode", {
        get: function () {
            var dialCode = this.phoneSelect.getCountryData().dialCode;
            return !_.isNil(dialCode) && dialCode !== '0' ? dialCode : '1';
        },
        enumerable: true,
        configurable: true
    });
    SearchboxNewGenerationComponent.prototype.onClickedOutsideTripContent = function (event) {
        if (_.eq(event.target.className, 'BusinessClassClick') ||
            _.eq(event.target.className, 'traveler') ||
            _.eq(event.target.className, 'fa fa-angle-down')) {
            return;
        }
        this.tripContent = false;
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsideCabinType = function (event) {
        if (_.eq(event.target.className, 'cabinClick') ||
            _.eq(event.target.className, 'cabinClick ng-star-inserted') ||
            _.eq(event.target.className, 'cabinClick fa fa-angle-down')) {
            return;
        }
        this.cabinContent = false;
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsideTripType = function (event) {
        if (_.eq(event.target.className, 'tripClick') ||
            _.eq(event.target.className, 'tripClick ng-star-inserted') ||
            _.eq(event.target.className, 'tripClick fa fa-angle-down')) {
            return;
        }
        this.tripTypeContent = false;
    };
    SearchboxNewGenerationComponent.prototype.onClickedOutsidePassengers = function (event) {
        if (_.eq(event.target.className, 'passengersClick') ||
            _.eq(event.target.className, 'passengersClick fa fa-angle-down')) {
            return;
        }
        this.passengersContent = false;
    };
    SearchboxNewGenerationComponent.prototype.initReturnDatepicker = function () {
        var _this = this;
        if (!isEmptyDate(this.flight.departDate)) {
            var date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
            this.minDateReturn = prepareDate(date, 1, 'd');
            if (!this.initFlightDataNow && this.changedDepartDateModel()) {
                setTimeout(function () { return _this.activeReturn = true; }, 0);
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.changedDepartDateModel = function () {
        return _.isEmpty(this.lastFlight.flights) ? true :
            !_.eq(this.lastFlight.flights[0].departDate, formatDate(this.activeForm.get('departDate').value));
    };
    SearchboxNewGenerationComponent.prototype.initDepartDatepicker = function () {
        if (!isEmptyDate(this.flight.departDate)) {
            var date = new Date(this.flight.departDate.year, this.flight.departDate.month - 1, this.flight.departDate.day);
            this.minDateDepart1 = prepareDate(date);
        }
        if (!isEmptyDate(this.flight1.departDate)) {
            var date = new Date(this.flight1.departDate.year, this.flight1.departDate.month - 1, this.flight1.departDate.day);
            this.minDateDepart2 = prepareDate(date);
        }
    };
    SearchboxNewGenerationComponent.prototype.openHowItWorksDialog = function () {
        var _this = this;
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.modalService.open(HowItWorksDialogComponent, {
            windowClass: 'hiw-modal',
            backdropClass: 'hiw-backdrop'
        }).result.then(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        }, function (reason) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        });
        // dialogRef.close().subscribe(result => {
        //
        //   console.trace('The dialog was closed');
        // });
    };
    SearchboxNewGenerationComponent.prototype.handleClick = function () {
        if (isPlatformBrowser(this.platformId)) {
            fitCountrySelect();
            this.initFlightDataNow = false;
            this.clickTripType = false;
        }
    };
    SearchboxNewGenerationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.isMobileOrTablet) {
            this.phoneSelect.el.nativeElement.previousElementSibling.style.pointerEvents = 'none';
        }
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                _this.submitSearch();
                fitCountrySelect();
                /* Set contact info */
                _this.searchboxService.getInitContactData().subscribe(function (response) {
                    if (!_.isNull(response.data.contactInfo.email)) {
                        _this.contactData = response.data.contactInfo;
                        _this.contactData.comments = '';
                        var splittedPhone = _.split(response.data.contactInfo.phone, ' ');
                        if (splittedPhone.length > 1) {
                            _this.contactData.phone = splittedPhone[1].replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                            _this.setCountry(_.replace(splittedPhone[0], '+', ''));
                        }
                    }
                });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.addFlight = function () {
        this.showAddFlightButton = false;
        this.showThirdFlight = true;
    };
    SearchboxNewGenerationComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
        // unsubscribe to ensure no memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    SearchboxNewGenerationComponent.prototype.onFocusOutAirportInput = function (event) {
        if (_.isNil(event.relatedTarget) || (!_.isNil(event.relatedTarget) && !_.includes(event.relatedTarget.className, 'mat-option'))) {
            this.changeMustSubmit(true);
            if (this.formSending === false) {
                this.submitSearch();
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.jumpToNextControl = function (element, autocompleteId) {
        var _this = this;
        if (!this.isMobileOrTablet) {
            if (_.eq(autocompleteId, 'flyingToRound') || _.eq(autocompleteId, 'flyingToOneWay') || _.eq(autocompleteId, 'flyingToMultiCity')) {
                setTimeout(function () { return _this.activeDepart = true; }, 0);
            }
            else if (_.eq(autocompleteId, 'flyingTo1MultiCity')) {
                setTimeout(function () { return _this.activeDepart1 = true; }, 0);
            }
            else if (_.eq(autocompleteId, 'flyingTo2MultiCity')) {
                setTimeout(function () { return _this.activeDepart2 = true; }, 0);
            }
            this.changeMustSubmit(true);
            if (element && !this.initFlightDataNow && !this.clickTripType) {
                if (element instanceof HTMLInputElement) {
                    this.setTimeOut(element);
                }
                else {
                    var nativeElement = element._elRef.nativeElement;
                    if (element && (_.includes(nativeElement.id, 'Return') || _.includes(nativeElement.id, 'Depart'))) {
                        setTimeout(function () {
                            var currentAutocomplete = getElementById(autocompleteId);
                            if (currentAutocomplete) {
                                currentAutocomplete.blur();
                            }
                            element.toggle();
                        }, 100);
                    }
                }
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.setTimeOut = function (element) {
        var _this = this;
        setTimeout(function () {
            if (_this.formSending) {
                interval(10)
                    .pipe(takeWhile(function () { return _this.formSending; }))
                    .subscribe(function () {
                    setTimeout(function () { return element.click(); }, 100);
                    // This will be called every 10 milliseconds until `formSending` flag is set to false
                });
            }
            else {
                setTimeout(function () { return element.click(); }, 100);
            }
        }, 100);
    };
    SearchboxNewGenerationComponent.prototype.capitalize = function (value) {
        return _.capitalize(value);
    };
    SearchboxNewGenerationComponent.prototype.onBlur = function (id) {
        var blurredElement = getElementById(id);
        if (_.isNil(blurredElement)) {
            return;
        }
        setTimeout(function () { return blurredElement.blur(); }, 0);
    };
    SearchboxNewGenerationComponent.prototype.noWhitespaceValidator = function (control) {
        var isWhitespace = (control.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'isEmpty': true };
    };
    Object.defineProperty(SearchboxNewGenerationComponent.prototype, "withForm", {
        get: function () {
            return !this.router.url.includes('faq') &&
                !this.router.url.includes('about-us') &&
                !this.router.url.includes('terms-and-conditions') &&
                !this.router.url.includes('terms-and-conditions-app') &&
                !this.router.url.includes('insurance-terms') &&
                !this.router.url.includes('privacy-policy') &&
                !this.router.url.includes('corporate') &&
                !this.router.url.includes('reviews') &&
                !this.router.url.includes('our-team') &&
                !this.router.url.includes('your-request') &&
                !this.router.url.includes('privacy-app') &&
                !this.router.url.includes('contact-us') &&
                !this.router.url.includes('app-page');
        },
        enumerable: true,
        configurable: true
    });
    SearchboxNewGenerationComponent.prototype.openMobileFlyingFromSelect = function (control, event, isFlyingFrom) {
        var _this = this;
        if (isFlyingFrom === void 0) { isFlyingFrom = false; }
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(FlyingFromDialogComponent, {
                windowClass: 'flying-from-modal',
                backdropClass: 'hiw-backdrop'
            });
            dialogRef.componentInstance.isFlyingFrom = isFlyingFrom;
            if (control.value)
                dialogRef.componentInstance.selectedAirport = transformToSquareBrackets(control.value);
            dialogRef.result.then(function (result) {
                event.target.blur();
                if (result) {
                    control.setValue(transformToRoundBrackets(result));
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.getControlName = function (c) {
        var formGroup = c.parent.controls;
        return Object.keys(formGroup).find(function (name) { return c === formGroup[name]; }) || null;
    };
    SearchboxNewGenerationComponent.prototype.openMobileFromToDialog = function (fromControl, toControl, event, isFlyingFrom, nextStepControlName) {
        var _this = this;
        if (isFlyingFrom === void 0) { isFlyingFrom = false; }
        if (nextStepControlName === void 0) { nextStepControlName = null; }
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(FlyingCombinedDirectionDialogComponent, {
                windowClass: 'flying-from-modal',
                backdropClass: 'hiw-backdrop'
            });
            dialogRef.componentInstance.isFlyingFrom = isFlyingFrom;
            dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
            if (fromControl.value)
                dialogRef.componentInstance.formDepartureValue = transformToSquareBrackets(fromControl.value);
            if (toControl.value)
                dialogRef.componentInstance.formDestinationValue = transformToSquareBrackets(toControl.value);
            dialogRef.result.then(function (result) {
                if (event)
                    event.target.blur();
                if (result) {
                    fromControl.setValue(transformToRoundBrackets(result.from));
                    toControl.setValue(transformToRoundBrackets(result.to));
                    if (_this.activeForm === _this.roundTripForm) {
                        _this.openMobileRoundTripDateDialog(_this.roundTripForm.get('departDate'), null);
                    }
                    else if (_this.activeForm === _this.oneWayForm) {
                        _this.openMobileDepartReturnDateDialog(_this.oneWayForm.get('departDate'), null, true);
                    }
                    else if (_this.activeForm === _this.multiCityForm) {
                        _this.openMobileDepartReturnDateDialog(_this.multiCityForm.get(nextStepControlName), null, true);
                    }
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                if (_this.getControlName(fromControl).includes('flyingFrom1')) {
                    _this.openMobileDepartReturnDateDialog(_this.multiCityForm.get('departDate'), null, true);
                }
                else if (_this.getControlName(fromControl).includes('flyingFrom2')) {
                    _this.openMobileDepartReturnDateDialog(_this.multiCityForm.get('departDate1'), null, true);
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openMobileFlyingToSelect = function () {
        var _this = this;
        // todo should check and delete
        // todo flying from/to captions
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(FlyingToDialogComponent, {
                windowClass: 'flyuing-to-modal',
                backdropClass: 'hiw-backdrop'
            }).result.then(function (result) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openMobileRoundTripDateDialog = function (control, event, isDepart) {
        var _this = this;
        if (isDepart === void 0) { isDepart = false; }
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(RoundTripDateDialogComponent, {
                windowClass: 'date-depart-modal',
                backdropClass: 'hiw-backdrop'
            });
            dialogRef.componentInstance.isDepart = isDepart;
            if (control.value) {
                dialogRef.componentInstance.selectedValue = control.value;
            }
            if (this.roundTripForm.get('departDate').value && this.roundTripForm.get('departDate').value.year) {
                dialogRef.componentInstance.startDay = this.roundTripForm.get('departDate').value;
            }
            if (this.roundTripForm.get('returnDate').value && this.roundTripForm.get('returnDate').value.year) {
                dialogRef.componentInstance.endDay = this.roundTripForm.get('returnDate').value;
            }
            dialogRef.result.then(function (result) {
                if (result) {
                    _this.roundTripForm.get('departDate').setValue(result.startDay);
                    _this.roundTripForm.get('returnDate').setValue(result.endDay);
                }
                if (event) {
                    event.target.blur();
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
                if (result) {
                    _this.openContactMobileDialog(null);
                }
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
                _this.openMobileFromToDialog(_this.roundTripForm.get('flyingFrom'), _this.roundTripForm.get('flyingTo'), null, true);
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openMobileDepartReturnDateDialog = function (control, event, isDepart) {
        var _this = this;
        if (isDepart === void 0) { isDepart = false; }
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(DepartReturnDateDialogComponent, {
                windowClass: 'date-depart-modal',
                backdropClass: 'hiw-backdrop'
            });
            dialogRef.componentInstance.isDepart = isDepart;
            dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
            if (control.value) {
                dialogRef.componentInstance.selectedValue = control.value;
            }
            dialogRef.result.then(function (result) {
                if (result) {
                    control.setValue(result);
                    if (_this.activeForm === _this.oneWayForm) {
                        _this.openContactMobileDialog(null);
                    }
                }
                if (event) {
                    event.target.blur();
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                if (_this.activeForm === _this.oneWayForm) {
                    _this.openMobileFromToDialog(_this.oneWayForm.get('flyingFrom'), _this.oneWayForm.get('flyingTo'), null, true);
                }
                else if (_this.activeForm === _this.multiCityForm) {
                    if (_this.getControlName(control).includes('2')) {
                        _this.openMobileFromToDialog(_this.multiCityForm.get('flyingFrom2'), _this.multiCityForm.get('flyingTo2'), null, true, 'departDate2');
                    }
                    else if (_this.getControlName(control).includes('1')) {
                        _this.openMobileFromToDialog(_this.multiCityForm.get('flyingFrom1'), _this.multiCityForm.get('flyingTo1'), null, false, 'departDate1');
                    }
                    else {
                        _this.openMobileFromToDialog(_this.multiCityForm.get('flyingFrom'), _this.multiCityForm.get('flyingTo'), null, false, 'departDate');
                    }
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openFlightOptionsDialog = function () {
        var _this = this;
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.searchboxFlightsMobileService.setFlightOptions({
                tripType: this.queryFlightParams.tripType,
                passengers: this.queryFlightParams.passengers,
                cabin: this.queryFlightParams.cabin
            });
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(FlightOptionsDialogComponent, {
                windowClass: 'date-return-modal',
                backdropClass: 'hiw-backdrop'
            });
            dialogRef.result.then(function (result) {
                var data = _this.flightMobileQuoteData.queryCruiseParams;
                _this.queryFlightParams.cabin = data.cabin;
                _this.queryFlightParams.tripType = data.tripType;
                _this.queryFlightParams.passengers = data.passengers;
                _this.focusOut = false;
                _this.clickTripType = true;
                if (_this.queryFlightParams.tripType === 'Round') {
                    _this.activeForm = _this.roundTripForm;
                }
                else if (_this.queryFlightParams.tripType === 'OneWay') {
                    _this.activeForm = _this.oneWayForm;
                }
                else if (_this.queryFlightParams.tripType === 'MultiCity') {
                    _this.activeForm = _this.multiCityForm;
                }
                if (_this.activeForm.valid) {
                    _this.submitSearch();
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.checkContactOpeningSource = function (event) {
        if (this.isMobileOrTablet) {
            if (this.activeForm === this.roundTripForm) {
                this.openMobileFromToDialog(this.roundTripForm.get('flyingFrom'), this.roundTripForm.get('flyingTo'), null, true);
                return;
            }
            else if (this.activeForm === this.oneWayForm) {
                this.openMobileFromToDialog(this.oneWayForm.get('flyingFrom'), this.oneWayForm.get('flyingTo'), null, true);
                return;
            }
            else {
                this.openContactMobileDialog(event);
            }
        }
    };
    SearchboxNewGenerationComponent.prototype.openContactMobileDialog = function (event) {
        var _this = this;
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(TravelerNameDialogComponent, {
                windowClass: 'traveler-name-modal',
                backdropClass: 'hiw-backdrop'
            });
            dialogRef.componentInstance.searchboxContactData = {
                cCode: '1',
                firstName: this.contactData.firstName,
                email: this.contactData.email,
                phone: _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '')
            };
            dialogRef.componentInstance.searchboxActiveForm = this.activeForm;
            dialogRef.componentInstance.isMultiCity = this.activeForm === this.multiCityForm;
            dialogRef.componentInstance.isOneWayForm = this.activeForm === this.oneWayForm;
            dialogRef.componentInstance.isMultiCityValid = this.activeForm === this.multiCityForm && this.thirdFlightValid();
            dialogRef.componentInstance.showThirdFlight = this.showThirdFlight;
            dialogRef.componentInstance.flightOptions = {
                travelers: this.queryFlightParams.passengers,
                cabin: this.queryFlightParams.cabin
            };
            dialogRef.result.then(function (result) {
                if (event) {
                    event.target.blur();
                }
                _this.dataService.sendData({ type: 'showModal', data: false });
                Object.keys(result.contactData).forEach(function (item) {
                    if (!_.isNil(result.contactData[item])) {
                        _this.contactData[item] = result.contactData[item];
                    }
                });
                if (_this.contactData.phone && _this.contactData.phone.length > 1) {
                    _this.contactData.phone = _this.contactData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                }
                if (result.isSuccess) {
                    _this.openFinalSuccessDialog();
                }
                if (result.moveBack) {
                    if (_this.activeForm === _this.roundTripForm) {
                        _this.openMobileRoundTripDateDialog(_this.roundTripForm.get('departDate'), null);
                    }
                    else if (_this.activeForm === _this.oneWayForm) {
                        _this.openMobileDepartReturnDateDialog(_this.oneWayForm.get('departDate'), null, true);
                    }
                }
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openNameDialog = function () {
        var _this = this;
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(TravelerNameDialogComponent, {
                windowClass: 'traveler-name-modal',
                backdropClass: 'hiw-backdrop'
            }).result.then(function (result) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openFinalSuccessDialog = function () {
        var _this = this;
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(TravelerPhoneDialogComponent, {
                windowClass: 'traveler-phone-modal',
                backdropClass: 'hiw-backdrop'
            }).result.then(function (result) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openEmailDialog = function () {
        var _this = this;
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.dataService.sendData({ type: 'showModal', data: true });
            var dialogRef = this.modalService.open(TravelerEmailDialogComponent, {
                windowClass: 'traveler-email-modal',
                backdropClass: 'hiw-backdrop'
            }).result.then(function (result) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            }, function (reason) {
                _this.dataService.sendData({ type: 'showModal', data: false });
            });
        }
    };
    SearchboxNewGenerationComponent.prototype.openBundleSaveDialog = function () {
        var _this = this;
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.modalService.open(BundleSaveDialogComponent, {
            windowClass: 'bundle-save-modal',
            backdropClass: 'hiw-backdrop'
        }).result.then(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        }, function (reason) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        });
    };
    SearchboxNewGenerationComponent.prototype.openTripTypeMobile = function () {
        if (this.isMobileOrTablet) {
            this.openFlightOptionsDialog();
        }
        else {
            this.tripTypeContent = !this.tripTypeContent;
        }
    };
    SearchboxNewGenerationComponent.prototype.openCabinTypeMobile = function () {
        if (this.isMobileOrTablet) {
            this.openFlightOptionsDialog();
        }
        else {
            this.cabinContent = !this.cabinContent;
        }
    };
    SearchboxNewGenerationComponent.prototype.openTravelersMobile = function () {
        if (this.isMobileOrTablet) {
            this.openFlightOptionsDialog();
        }
        else {
            this.passengersContent = !this.passengersContent;
        }
    };
    SearchboxNewGenerationComponent.prototype.getDatePlaceholder = function (dateObject, basePlaceholder) {
        if (this.isMobile && dateObject && dateObject.year) {
            var date = new Date(dateObject.year, dateObject.month - 1, dateObject.day);
            // todo check desktop version
            // todo check ipad mini(tablet)
            // todo check type of data from server
            return moment(date).format('ddd MMM D');
        }
        else {
            return basePlaceholder;
        }
    };
    SearchboxNewGenerationComponent.prototype.getFromToPlaceholder = function (flyingData, basePlaceholder) {
        if (this.isMobile && flyingData) {
            // todo check desktop version
            // todo check ipad mini(tablet)
            // todo check type of data from server
            return this.removeBrackets(flyingData);
        }
        else {
            return basePlaceholder;
        }
    };
    SearchboxNewGenerationComponent.prototype.displayFn = function (value) {
        if (this.isMobile && value) {
            return extractAirportCodeFromBrackets(value);
        }
        else {
            return value;
        }
    };
    SearchboxNewGenerationComponent.prototype.countryChanged = function () {
        this.queryPanelForm.get('phone').updateValueAndValidity();
    };
    return SearchboxNewGenerationComponent;
}());
export { SearchboxNewGenerationComponent };
