/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../templates/searchbox-new-generation/searchbox-new-generation.component.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../model/ngb-date-custom-parser-formatter-flights";
import * as i5 from "ngx-device-detector";
import * as i6 from "../../templates/searchbox/searchbox.service";
import * as i7 from "@angular/common/http";
import * as i8 from "../../templates/searchbox-new-generation/searchbox-new-generation.component";
import * as i9 from "@angular/forms";
import * as i10 from "ngx-cookie-service";
import * as i11 from "@angular/router";
import * as i12 from "../../../../shared/shared-data.service";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../storage/universal.inject";
import * as i15 from "../../../services/tracking.service";
import * as i16 from "../../../services/notify.service";
import * as i17 from "../../../services/searchbox-flights-mobile.service";
import * as i18 from "ng-recaptcha";
import * as i19 from "@angular/common";
import * as i20 from "../../templates/section-one/section-one.component.ngfactory";
import * as i21 from "../../templates/section-one/section-one.component";
import * as i22 from "../../templates/section-two/section-two.component.ngfactory";
import * as i23 from "../../templates/section-two/section-two.component";
import * as i24 from "../../../services/call-me.service";
import * as i25 from "../../templates/section-three/section-three.component.ngfactory";
import * as i26 from "../../templates/section-three/section-three.component";
import * as i27 from "../../templates/service/service.component.ngfactory";
import * as i28 from "../../templates/service/service.component";
import * as i29 from "../../templates/newsletter-form/newsletter-form.component.ngfactory";
import * as i30 from "../../templates/newsletter-form/newsletter-form.service";
import * as i31 from "../../templates/newsletter-form/newsletter-form.component";
import * as i32 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-searchbox-new-generation", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SearchboxNewGenerationComponent_0, i2.RenderType_SearchboxNewGenerationComponent)), i1.ɵprd(4608, null, i3.NgbDateParserFormatter, i4.NgbDateCustomParserFormatterFlights, [i5.DeviceDetectorService]), i1.ɵprd(512, null, i6.SearchboxService, i6.SearchboxService, [i7.HttpClient]), i1.ɵdid(3, 4440064, null, 0, i8.SearchboxNewGenerationComponent, [i9.FormBuilder, i6.SearchboxService, i10.CookieService, i11.ActivatedRoute, i1.PLATFORM_ID, i11.Router, i12.DataService, i13.TransferState, i14.AppStorage, i15.TrackingService, i16.NotifyService, i3.NgbModal, i5.DeviceDetectorService, i17.SearchboxFlightsMobileService, i18.ReCaptchaV3Service], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(1, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-section-one", [], null, null, null, i20.View_SectionOneComponent_0, i20.RenderType_SectionOneComponent)), i1.ɵdid(3, 114688, null, 0, i21.SectionOneComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-section-two", [], null, null, null, i22.View_SectionTwoComponent_0, i22.RenderType_SectionTwoComponent)), i1.ɵdid(5, 114688, null, 0, i23.SectionTwoComponent, [i24.CallMeService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-section-three", [], null, null, null, i25.View_SectionThreeComponent_0, i25.RenderType_SectionThreeComponent)), i1.ɵdid(7, 114688, null, 0, i26.SectionThreeComponent, [i24.CallMeService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-service", [], null, null, null, i27.View_ServiceComponent_0, i27.RenderType_ServiceComponent)), i1.ɵdid(9, 49152, null, 0, i28.ServiceComponent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-newsletter-form", [], null, null, null, i29.View_NewsletterFormComponent_0, i29.RenderType_NewsletterFormComponent)), i1.ɵprd(512, null, i30.NewsletterFormService, i30.NewsletterFormService, [i7.HttpClient, i11.Router]), i1.ɵdid(12, 114688, null, 0, i31.NewsletterFormComponent, [i30.NewsletterFormService, i10.CookieService, i9.FormBuilder, i1.PLATFORM_ID], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDefaultForm; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 12, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i32.HomeComponent, [i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i32.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
